<template>
  <div class="bg-white rounded">
    <DynamicInvoiceModal
      :htmlString="invoiceHtmlString"
      :id="invoiceId"
      pdfName="subscription"
      @send-email="sendInvoiceEmail"
    />
    <InvoiceEdit @refresh="$store.dispatch('fsTable/fetchData')" />
    <div class="px-4 mt-2">
      <TitlePlus :title="pageTitle" :hide-plus="true" class="mb-10" />
    </div>

    <div class="flex flex-col px-4 mt-4 mb-10 md:items-center md:flex-row">
      <SummaryCard
        title="Total Invoices"
        :value="indexMetaData.count.total"
        variant="gray"
        class="mr-3"
      />
    </div>

    <FSTable
      :fst-id="fstId"
      :headers="getTableHeaders"
      :endpoint="getEndpoint"
      :qso="{ append: '', prepend: '&' }"
      @meta="(e) => (indexMetaData = e)"
    >
      <template #default="{ data }">
        <FSTableRow
          v-for="(item, itemIndex) in data"
          :key="itemIndex"
          text-fallback-always
        >
          <!-- ./v-if[invoice] -->
          <template v-if="tableHeadersPreset === 'invoice'">
            <FSTableRowItem :text="item.id" :truncate="-5" />

            <FSTableRowItem :text="item.issue_date" date />

            <FSTableRowItem
              :text="item.due_date"
              class-name="text-oOrange"
              date
            />

            <FSTableRowItem>
              <XStatus
                :text="item.status_display_text"
                :profile="`payment`"
                :variant="item.status === 'P' ? 'green' : 'orange'"
              />
            </FSTableRowItem>

            <FSTableRowItem :text="item.currency.name" />

            <FSTableRowItem :text="item.currency.symbol + item.amount" />

            <FSTableRowItem>
              <div class="flex items-center">
                <oto-eye-icon
                  v-tooltip.bottom="'View Invoice'"
                  @click="onInvoiceModalReq(item.id)"
                />
                <OtoEditIcon @click="editInvoice(item)" />
              </div>
            </FSTableRowItem>

            <!-- <FSTableRowItem>
              <AppButton
                height="27px"
                width="60px"
                rounded="none"
                variant="blue"
                text="Pay"
              />
            </FSTableRowItem> -->
          </template>
          <!-- ./v-if[invoice] -->

          <!-- v-if[subscription] -->
          <template v-else-if="tableHeadersPreset === 'transaction'">
            <FSTableRowItem :text="item.transaction" :truncate="-5" />

            <FSTableRowItem :text="item.id" :truncate="-5" />

            <FSTableRowItem :text="item.payment_date" date />

            <FSTableRowItem>
              <i
                class="text-lg"
                :class="getCreditCardIcon(item.payment_method.brand)"
              />
              {{ '**** ' + $truncate(item.payment_method.number, -4) }}
            </FSTableRowItem>

            <FSTableRowItem
              :text="item.currency.symbol + item.amount"
              :class-name="`text-oGreen`"
            />

            <FSTableRowItem>
              <AppButton height="30px" width="30px" variant="secondary">
                <i class="fa fa-download" />
              </AppButton>
            </FSTableRowItem>

            <FSTableRowItem :text="item.checkout_attempts" />
          </template>
          <!-- ./v-if[subscription] -->
        </FSTableRow>
      </template>
    </FSTable>
  </div>
</template>

<script>
import { useEndpoints } from '@/composables'
import { FSTable, FSTableRow, FSTableRowItem } from '@/components/fs-table'
import { EventBus, getCreditCardIcon } from '@/utils'

import TitlePlus from '@/components/ui/TitlePlus'
import SummaryCard from '@/components/cards/SummaryCard'
import XStatus from '@/components/badge/XStatus.vue'
import { OrgInvoiceConfig } from '@/config/OrgInvoiceConfig'

const tableHeadersPreset = {
  invoice: [
    { text: 'ID', width: '10%', sort: 'id' },
    { text: 'Issue Date', width: '30%', sort: 'rider' },
    { text: 'Due Date', width: '15%', sort: 'date' },
    { text: 'Status', width: '15%', sort: 'date' },
    { text: 'Currency', width: '20%', sort: null },
    { text: 'Amount', width: '20%', sort: null },
    { text: 'Invoice', width: '20%', sort: null },
    // { text: 'Actions', width: '10%', sort: null },
  ],
  transaction: [
    { text: 'Trx. ID', width: '10%', sort: 'id' },
    { text: 'Invoice ID', width: '10%', sort: 'rider' },
    { text: 'Trx. Date', width: '20%', sort: 'date' },
    { text: 'Payment Method', width: '15%', sort: 'date' },
    { text: 'Paid Amount', width: '15%', sort: null },
    { text: 'Invoice', width: '10%', sort: null },
    { text: 'Checkout Attempts', width: '20%', sort: null },
  ],
}

export default {
  name: 'SubscriptionChargesIndex',

  props: {
    pageTitle: {
      type: String,
      default: 'Subscription Charges',
    },

    fstId: {
      type: String,
      default: 'subscriptionCharges',
    },

    // it'll get priority over the tableHeadersPreset prop
    tableHeaders: {
      type: [Array, Boolean],
      validator: (val) => {
        if (typeof val === 'boolean') {
          return true
        }

        const hasItems = val.length > 0
        const hasProps = val.every((item) => {
          return (
            item?.text?.length > 0 &&
            item?.width?.length > 0 &&
            (typeof item.sort === 'string' || item.sort === null)
          )
        })
        return hasItems && hasProps
      },
      default: false,
    },

    tableHeadersPreset: {
      type: String,
      validator: (val) => ['invoice', 'transaction'].includes(val),
      default: 'invoice',
    },

    paidOnly: {
      type: Boolean,
      default: false,
    },

    unpaidOnly: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    FSTable,
    FSTableRow,
    FSTableRowItem,

    TitlePlus,
    SummaryCard,
    XStatus,
    OtoEyeIcon: () => import('@/components/ui/OtoEyeIcon'),
    OtoEditIcon: () => import('@/components/ui/OtoEditIcon'),
    InvoiceEdit: () =>
      import('@/composites/organization/details/InvoiceEdit.vue'),
    DynamicInvoiceModal: () =>
      import('@/components/modals/DynamicInvoiceModal.vue'),
  },

  data() {
    return {
      indexMetaData: {
        count: {
          total: 0,
        },
        summary: {
          total_unpaid_amount: 0,
          total: 1,
          paid: 1,
          unpaid: 0,
          past_due: 0,
        },
      },
      // default currency symbol for subscription charges
      currencySymbolDef: '$',

      addEdit: {
        model: '',
      },
      invoiceHtmlString: '',
      invoiceId: '',
    }
  },

  computed: {
    reqId() {
      return this.$route.params.id
    },

    getEndpoint() {
      return useEndpoints.organization.subscriptionInvoice.index({
        paidOnly: this.paidOnly,
        unpaidOnly: this.unpaidOnly,
        orgId: this.reqId,
      })
    },

    getTableHeaders() {
      if (Array.isArray(this.tableHeaders)) {
        return this.tableHeaders
      }
      return tableHeadersPreset[this.tableHeadersPreset]
    },
  },

  methods: {
    getCreditCardIcon,
    async onInvoiceModalReq(id) {
      this.invoiceId = id
      this.$notify({
        group: 'generic',
        type: 'default',
        title: `Processing`,
        text: 'The action is being executed...',
      })

      await this.$http
        .get(
          useEndpoints.organization.subscriptionInvoice.getInvoiceEmailInfo(id)
        )
        .then((res) => {
          this.invoiceHtmlString = res.data
          this.$modal.show('dynamicInvoiceModal')
        })
        .catch((err) => {
          console.log('email-template-err', err)
          this.$notify({
            group: 'generic',
            type: 'error',
            title: `Server Error [${err.response.status}]`,
            text: 'Unable to process the request!',
          })
        })
    },
    async sendInvoiceEmail(id) {
      await this.$http
        .put(
          useEndpoints.organization.subscriptionInvoice.sendInvoiceEmailInfo(id)
        )
        .then(() => {
          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: 'Success',
              text: 'Send Successfully',
            },
            3000
          )
        })
        .catch((err) => {
          console.log('send-email-err', err)
          this.$notify({
            group: 'generic',
            type: 'error',
            title: `Server Error [${err.response.status}]`,
            text: 'Unable to process the request!',
          })
        })
    },
    async editInvoice(data) {
      if (data.id) {
        this.$notify(
          {
            group: 'generic',
            type: 'default',
            title: `Processing..`,
            text: 'The action is being executed...',
          },
          600
        )
        const requests = []
        const subscriptionInvoiceDetailsReq = this.$http.get(
          useEndpoints.organization.subscriptionInvoice.details(data.id)
        )
        requests.push(subscriptionInvoiceDetailsReq)
        const currencyReq = this.$http.get(useEndpoints.dropdown.currency())
        requests.push(currencyReq)

        const bankDetailsReq = this.$http.get(
          useEndpoints.organization.subscriptionInvoice.getBankDetails()
        )
        requests.push(bankDetailsReq)
        await this.$http
          .all(requests)
          .then(
            this.$http.spread((...responses) => {
              const [
                subscriptionInvoiceDetailsRes,
                currencyRes,
                bankDetailsRes,
              ] = responses
              console.log('subscriptionInvoiceDetailsRes', bankDetailsRes.data)
              const invoiceData = subscriptionInvoiceDetailsRes.data

              EventBus.$emit(OrgInvoiceConfig.events.editingData, {
                formData: {
                  id: invoiceData.id,
                  issue_date: this.dateSplitter(invoiceData.issue_date),
                  due_date: this.dateSplitter(invoiceData.due_date),
                  currency: invoiceData.currency.id,
                  bank: invoiceData.bank,
                  online_payment_link: invoiceData.online_payment_link,
                  status: invoiceData.status,
                  discount_type: invoiceData.discount_type,
                  flat_discount_amount: invoiceData.flat_discount_amount,
                  rate_discount_percentage:
                    invoiceData.rate_discount_percentage,
                  items: invoiceData.items,
                  vat_percentage: parseFloat(data.vat_percentage),
                },
                extraData: {
                  minimum_fee: invoiceData.subscription.minimum_fee,
                  is_minimum_fee_applied: invoiceData.is_minimum_fee_applied,
                  currencies: currencyRes.data.data,
                  bankDetails: bankDetailsRes.data,
                  useOnlinePayment: !!invoiceData.online_payment_link,
                },
              })
              dispatchEvent(new Event(OrgInvoiceConfig.events.sorToggle))
            })
          )
          .catch((err) => {
            console.error('Error occurred!', err.response.data)
            this.$notify(
              {
                group: 'generic',
                type: 'error',
                title: `Failed![${err.response.status}]`,
                text: err.response.data,
              },
              5000
            )
            console.log({ subErr: err.response.data })
          })
          .finally(() => (this.isLoading = false))
      }
    },
    dateSplitter(dString) {
      return dString.split('T')[0]
    },
  },
}
</script>

<style lang="scss" scoped></style>
